<template>
    <ul @click="sideBarClick()">
        <component
            :is="resolveNavItemComponent(item)"
            :item="$t(item)"
            :key="item.header || item.title"
            v-for="item in myItems"
        />

        <li @click="checkStatus()" id="updateSideBar"></li>
    </ul>
</template>

<script>
  import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from "@core/layouts/utils";
  import {provide, ref} from "@vue/composition-api";
  import VerticalNavMenuHeader from "../vertical-nav-menu-header";
  import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
  import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
  import {mapGetters} from "vuex";
  import store from '@/store'

  export default {
    components: {
      VerticalNavMenuHeader,
      VerticalNavMenuLink,
      VerticalNavMenuGroup,
    },
    props: {
      items: {
        type: Array,
        checkMyRole: false,
        showNav: false,
        required: true,
      },
    },
    data() {
      return {
        company: null,
        myItems: [],
        load: false,
      };
    },
    computed: {
      ...mapGetters(["showInvoiceGetters", "showDocsGetters"]),
      invoice() {
        return this.$store.state.verticalMenu.showInvoice;
      },
      docs() {
        return this.$store.showDocs;
      },
      loader() {
        return this.load;
      },
    },
    created() {
      this.checkStatus();
    },
    methods: {
      sideBarClick() {
        this.$store.commit("app/ON_SIDEBAR_CLICK", true);
        this.$store.commit("auth/SHOW_ALERT", false);
      },
      async checkStatus() {
        this.myItems = [];
        this.myItems.push({
          title: "dashboard",
          route: "home",
          icon: "HomeIcon",
        });
        let user = this.$store.state.auth.user;
        await this.$http.post("/company/tasks").then((res) => {
          this.company =
              this.$store.state.app &&
              this.$store.state.app.company &&
              this.$store.state.app.company.value
                  ? this.$store.state.app.company.value
                  : null;
          if (
              res.data.value && user.crole == "seller"
          ) {
            let data = res.data.value;
            this.seller(data);
          }
          if (
              res.data.value && user.crole == "buyer"
          ) {
            let data = res.data.value;
            this.buyer(data);
          }
        });
        // this.myItems.push({
        //   title: "my_balance",
        //   route: "balance",
        //   icon: "FileTextIcon",
        // });

      },
      async buyer(data) {
        let ready = true;
        await data.map((item) => {
          if (item.status != 1) {
            ready = false;
          }
        });

        if (ready == true || store.state.auth.user.access == null) {
          if (this.$store.state.auth.user.crole == "buyer") {
            // this.myItems.push({
            //   title: "check_hash",
            //   route: "check_hash",
            //   icon: "ArchiveIcon",
            // });
            if (store.state.auth.user.access != false) {
              this.myItems.push({
                title: "market",
                route: "market",
                icon: "ShoppingCartIcon",
              });
              if (this.company.name != null) {
                this.myItems.push({
                  title: "documents",
                  route: "upload-docs",
                  icon: "FileIcon",
                });
              }
            }
            this.myItems.push({
              title: "billing",
              route: "billing",
              icon: "CreditCardIcon",
              id: "sideBarProgram",
            });
            if (store.state.auth.user.access != false) {
                this.myItems.push({
                  title: "identification",
                  route: "identification",
                  icon: "UserCheckIcon",
                });

                this.myItems.push({
                    title: "Team",
                    route: "team",
                    icon: "UsersIcon",
                    id: "companyTeam",
                });

            }
          }

        }
        this.load = true;
        this.$forceUpdate();
      },
      seller(data) {
        let invoice = false;
        let docs = false;
        if (
            (data[1].status == 2 || data[1].status == 1) &&
            data[1].task_id == 2
        ) {
          invoice = true;
        }
        if (
            (data[2].status == 2 || data[2].status == 1) &&
            data[2].task_id == 3
        ) {
          docs = true;
        }
        this.myItems.forEach((el) => {
          if (el.title == "Invoices" || el.title == "Rechnungen") {
            invoice = false;
          }
          if (el.title == "Documents" || el.title == "Dokumentation") {
            docs = false;
          }
        });
        if (this.$store.state.auth.user.crole == "seller") {
          if (invoice) {
            if (store.state.auth.user.access != false) {
              this.$store.commit("app/CHECK_FOR_INVOICE", true);
              this.myItems.push({
                title: "invoices",
                route: "invoices",
                icon: "FileTextIcon",
                id: "sideBarInvoices",
              });
            }
          }
          // if (docs) {
            if (store.state.auth.user.access != false) {
              this.$store.commit("app/CHECK_FOR_DOCS", true);
              this.myItems.push({
                title: "documents",
                route: "documents",
                icon: "FileIcon",
                id: "sideBarDocument",
              });
            }
          // }
          if (store.state.auth.user.access != false) {
            this.myItems.push({
              title: "referral_program",
              route: "referralProgram",
              icon: "FileIcon",
              id: "sideBarProgram",
            });
          }
          this.myItems.push({
            title: "billing",
            route: "billing",
            icon: "CreditCardIcon",
            id: "sideBarProgram",
          });
          if (store.state.auth.user.access != false) {
            this.myItems.push({
              title: "Team",
              route: "team",
              icon: "UsersIcon",
              id: "companyTeam",
            });
          }

        }
        this.$http
            .post(`/company/show/${this.$store.state.auth.user.company_id}`)
            .then((res) => {
              if (res.data) {

                this.$store.commit("app/SET_COMPANY_INFO", res.data);
                if (res.data.value.buyer == 1) {
                  this.myItems.push({
                    title: "market",
                    route: "market",
                    icon: "ShoppingCartIcon",
                  });
                }


              }
            });
        this.load = true;
        this.$forceUpdate();
      },
    },
    setup() {
      provide("openGroups", ref([]));
      return {
        resolveNavItemComponent,
      };
    },
  };
</script>

